import { tableRowClasses } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  projectDetails: {},
  EditedRows: [],
  reload: 0,
  newRows: [],
  hiddenRows: {},
  tableRows: {}, // hidden + tabledata
  deletedRows: [],
  tableRowsData: [],
  projectsAccessLevelValue: "",
  projectName: "",
  nonWorkingDays: [],
  WorkingDays: [],
  unsavedChanges: false,
  unsavedChangesModal: false,
};

let newRow = (name) => ({
  entryID: "",
  specSection: name,
  specNumber: "",
  specTitle: "",
  specRevision: "",
  specType: "",
  specResponse: "",
  responsibleContractor: "",
  issueContract: "",
  contractIssuedRelease: "",
  submittalPrepTime: "",
  SubmittalRequired: "",
  submittalIssued: "",
  approvalTime: "",
  submittalApprovalDue: "",
  submittalReturned: "",
  leadTime: "",
  scheduleID: "",
  dateRequiredOnSite: "",
  projectedDeliveryDate: "",
  subCommittedDeliveryDate: "",
  actualDeliveryDate: "",
  custom1: "",
  custom2: "",
  custom3: "",
  custom4: "",
  custom5: "",
  custom6: "",
  custom7: "",
  custom8: "",
  custom9: "",
  custom10: "",
  custom11: "",
  custom12: "",
  custom13: "",
  custom14: "",
  custom15: "",
  custom16: "",
  custom17: "",
  custom18: "",
  custom19: "",
  custom20: "",
  custom21: "",
  custom22: "",
  custom23: "",
  custom24: "",
  custom25: "",
  entryOrigin: "Koncurent",
  isEdited: false,
  div: "",
  subDiv: "",
  index: "",
  isNew: false,
  isDeleted: false,
  isHidden: true,
});

const persistConfig = {
  key: "root",
  storage,
};

export const ProjectSlice = createSlice({
  name: "ProjectDetails",
  initialState,
  reducers: {
    addHiddenRows: (state, action) => {
      const { subDivisionName, data } = action.payload;

      // Filter existing new rows for this subdivision
      const existingNewRows = state.newRows.filter(
        (row) => row.subDiv === subDivisionName
      );

      // Filter existing edited rows for this subdivision
      const existingEditedRows = state.EditedRows.filter(
        (row) => row.subDiv === subDivisionName
      ).map((row) => ({
        ...row,
        entryID: row.id,
        id: undefined,
      }));

      // Combine provided data and new rows
      let combinedData = [...(data || []), ...existingNewRows];

      // Apply edited rows
      existingEditedRows.forEach((editedRow) => {
        const index = combinedData.findIndex(
          (row) => row.entryID === editedRow.entryID
        );
        if (index !== -1) {
          combinedData[index] = editedRow;
        }
      });

      // Store rows by subdivision
      state.tableRows[subDivisionName] = combinedData;
      state.hiddenRows[subDivisionName] = []; // Initialize as empty array
    },

    showHiddenRow: (state, action) => {
      // const subDivisionName = action.payload;
      // // Check if tableRows for the subdivision exists
      // if (!state.tableRows[subDivisionName]) {
      //   state.tableRows[subDivisionName] = [];
      // }
      // // Add a new row dynamically
      // state.tableRows[subDivisionName].push({
      //   ...newRow(subDivisionName),
      //   subDiv: subDivisionName,
      //   isNew: true,
      //   isHidden: false,
      // });
    },

    saveProjectDetails: (state, action) => {
      state.projectDetails = action.payload;
    },
    addNewRow: (state, action) => {
      state.projectDetails.content[action.payload.div][
        action.payload.subDiv
      ].push(newRow(action.payload.subDiv));
    },
    deleteRow: (state, action) => {
      // let payload = action.payload;
      // let index = state.deletedRows.findIndex(
      //   (obj) =>
      //     obj.index === payload.index &&
      //     obj.values.subDiv === payload.values.subDiv
      // );
      // if (index == -1) {
      //   state.deletedRows.push(payload);
      // } else {
      //   state.deletedRows.splice(index, 1);
      // }
    },
    clearDeletedRows: (state) => {
      state.deletedRows = [];
    },
    EditRow: (state, action) => {
      let itemIndex = state.EditedRows.findIndex(
        (obj) =>
          obj["div"] === action.payload["div"] &&
          obj["subDiv"] === action.payload["subDiv"] &&
          obj["id"] === action.payload["id"]
      );

      if (itemIndex == -1) {
        state.EditedRows.push(action.payload);
      } else {
        state.EditedRows[itemIndex] = { ...action.payload };
      }
    },

    reloadProjects: (state, action) => {
      state.reload = state.reload + 1;
    },
    editNewRows: (state, action) => {
      // let newArr = [...state.newRows];

      let index = state.newRows.findIndex((x) => {
        return (
          x["div"] == action.payload["div"] &&
          x["subDiv"] == action.payload["subDiv"] &&
          x["index"] == action.payload["index"]
        );
      });

      if (index == -1) {
        state.newRows.push(action.payload);
      } else {
        state.newRows[index] = { ...action.payload };
      }
    },

    clearNewRows: (state, action) => {
      state.newRows = [];
      state.EditedRows = [];
    },
    clearAllRows: (state, action) => {
      state.hiddenRows = {};
      state.tableRows = {};
      state.newRows = [];
      state.EditedRows = [];
      state.deletedRows = [];
    },

    projectDetailsToDefault: (state, action) => {
      state.projectDetails = [];
    },

    projectsAccessLevel: (state, action) => {
      state.projectsAccessLevelValue = action.payload;
    },

    SetProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    nonWorkingDays: (state, action) => {
      state.nonWorkingDays = action.payload;
    },
    WorkingDays: (state, action) => {
      state.WorkingDays = action.payload;
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload;
    },
    setUnsavedChangesModal: (state, action) => {
      state.unsavedChangesModal = action.payload;
    },
  },
});
const persistedReducer = persistReducer(persistConfig, ProjectSlice.reducer);

// Action creators are generated for each case reducer function
export const {
  saveProjectDetails,
  addNewRow,
  EditRow,
  deleteRow,
  clearDeletedRows,
  reloadProjects,
  editNewRows,
  rowsData,
  updateTableRowsData,
  clearNewRows,
  clearAllRows,
  projectDetailsToDefault,
  projectsAccessLevel,
  SetProjectName,
  nonWorkingDays,
  WorkingDays,
  setUnsavedChanges,
  setUnsavedChangesModal,
  addHiddenRows,
  showHiddenRow,
} = ProjectSlice.actions;

export default ProjectSlice.reducer;
