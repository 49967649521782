import React from "react";
import DivisionItemHeader from "./sub-division-item-header";
import SubDivisionTable from "./sub-division-table";

const SubDiv = React.memo(
  ({
    divName,
    name,
    data,
    setcollapsed,
    collapsed,
    handleCollapse,
    index,
    checkedRows,
    setCheckedRows,
    setEditedItems,
    setNewItems,
    newItems,
    deletedItems,
    setDeletedItems,
  }) => {
    return (
      <>
        <DivisionItemHeader
          collapsed={collapsed}
          setcollapsed={setcollapsed}
          name={name}
          length={data.length}
          handleCollapse={(x) => handleCollapse(x)}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          setEditedItems={setEditedItems}
          setNewItems={setNewItems}
          newItems={newItems}
          deletedItems={deletedItems}
          setDeletedItems={setDeletedItems}
        />
        {collapsed.includes(name) && (
          <SubDivisionTable
            collapsed={collapsed}
            data={data}
            setcollapsed={setcollapsed}
            divName={divName}
            subDivisionName={name}
            name={name}
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
            setEditedItems={setEditedItems}
            setNewItems={setNewItems}
            newItems={newItems}
            deletedItems={deletedItems}
            setDeletedItems={setDeletedItems}
          />
        )}
      </>
    );
  }
);

const PanelContent = React.memo(
  ({
    divName,
    subDivData,
    collapsed,
    setcollapsed,
    handleCollapse,
    checkedRows,
    setCheckedRows,
    setEditedItems,
    setNewItems,
    newItems,
    deletedItems,
    setDeletedItems,
  }) => {
    return (
      <>
        {Object.keys(subDivData)?.map((subDivisionName, index) => {
          return (
            <div
              className="my-3"
              key={
                "subDivisionTable" +
                index +
                JSON.stringify(subDivData[subDivisionName])
              }
            >
              <SubDiv
                collapsed={collapsed}
                setcollapsed={setcollapsed}
                name={subDivisionName}
                data={subDivData[subDivisionName]}
                divName={divName}
                handleCollapse={(x) => handleCollapse(x)}
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
                setEditedItems={setEditedItems}
                setNewItems={setNewItems}
                newItems={newItems}
                deletedItems={deletedItems}
                setDeletedItems={setDeletedItems}
              />
            </div>
          );
        })}
      </>
    );
  }
);

const ProjectDivision = React.memo(
  ({
    name,
    projectSubDivision = {},
    setcollapsed,
    collapsed,
    handleCollapse,
    index,
    collapsedDiv,
    checkedRows,
    setCheckedRows,
    setEditedItems,
    setNewItems,
    newItems,
    deletedItems,
    setDeletedItems,
  }) => {
    return (
      <PanelContent
        setcollapsed={setcollapsed}
        collapsed={collapsed}
        divName={name}
        subDivData={projectSubDivision}
        handleCollapse={(x) => handleCollapse(x)}
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
        setEditedItems={setEditedItems}
        setNewItems={setNewItems}
        newItems={newItems}
        deletedItems={deletedItems}
        setDeletedItems={setDeletedItems}
      />
    );
  }
);

export default ProjectDivision;
