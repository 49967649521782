import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import { ReportServices } from "apis/Services/ReportService";
import { saveReportAccessLevel } from "redux/permission-reducer";
import { useDispatch, useSelector } from "react-redux";
import ProjectHeader from "components/project-header";
import styles from "./reports.module.css";
import Container from "components/container";
import Text from "components/text";
import { Button, Tooltip, Table, Collapse, Input } from "antd";
import LoadingComponent from "components/loading-component";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import ProjectReports from "pages/project-reports";
import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteFilled,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import moment from "moment";

export default function ProjectCustomReport() {
  const { company_id, id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [isSharedLoading, setisSharedLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const [customReportsInfo, setCustomReportsInfo] = useState([]);
  const [sharedReportsInfo, setSharedReportsInfo] = useState([]);

  const navigate = useNavigate();
  const { Panel } = Collapse;

  useEffect(() => {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
    };
    ReportServices.readCustomReports(ReqObj)
      .then((res) => {
        setAccessLevel(parseInt(res.userInfo.accessLevel));
        setCustomReportsInfo(res.reportsInfo);
        console.log(res, "!!");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisLoading(false));
  }, []);

  useEffect(() => {
    setisSharedLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
    };
    ReportServices.readSharedCustomReport(ReqObj)
      .then((res) => {
        setSharedReportsInfo(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisSharedLoading(false));
  }, []);

  const handleEdit = (item) => {
    // const selectedView = viewsList.find(
    //   (view, index) => view.viewName === item.view
    // );
  };

  const handleView = (item) => {
    // const selectedView = viewsList.find(
    //   (view, index) => view.viewName === item.view
    // );
  };

  const handleDelete = (item) => {
    // const selectedView = viewsList.find(
    //   (view, index) => view.viewName === item.view
    // );
  };

  const dataSource = customReportsInfo?.map((report, index) => ({
    key: index.toString(),
    report: report.reportName,
    reportDescription: report.reportDescription,
    reportTabs: report.reportTabs,
    reportOwnerName: report.reportOwnerName,
    reportOwnerEmail: report.reportOwnerEmail,
    createdDate: report.createdDate
      ? moment(report.createdDate).format("MM/DD/YY")
      : "",
    modifiedDate: report.modifiedDate
      ? moment(report.modifiedDate).format("MM/DD/YY")
      : "",
  }));

  const sharedDataSource = sharedReportsInfo?.map((report, index) => ({
    key: index.toString(),
    report: report.reportName,
    reportDescription: report.reportDescription,
    reportTabs: report.reportTabs,
    reportOwnerName: report.reportOwnerName,
    reportOwnerEmail: report.reportOwnerEmail,
    createdDate: report.createdDate
      ? moment(report.createdDate).format("MM/DD/YY")
      : "",
    modifiedDate: report.modifiedDate
      ? moment(report.modifiedDate).format("MM/DD/YY")
      : "",
  }));

  const columns = [
    {
      title: "Report",
      dataIndex: "report",
      key: "report",
      render: (text, record) => (
        <div style={{ cursor: "pointer" }}>
          <Tooltip title="View">
            <Link
              to={`${record.report}?owner=${encodeURIComponent(
                record.reportOwnerEmail
              )}&tab=${encodeURIComponent(record.reportTabs[0])}`}
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={console.log(record)}
            >
              {text}
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "reportDescription",
      key: "reportDescription",
    },
    {
      title: "Tabs",
      dataIndex: "reportTabs",
      key: "reportTabs",
      render: (text, record) => (
        <div>
          <div className=" d-flex flex-column">
            {record.reportTabs.map((item, index) => (
              <small key={index}>{item}</small>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Owner",
      dataIndex: "reportOwnerName",
      key: "reportOwnerName",
    },
    {
      title: "Date Created",
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: "Last Modified",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
    },
  ];

  // Dynamically add the "Actions" column
  const getColumns = (type) => {
    return [
      ...columns,
      {
        title: "Actions",
        align: "center",
        key: "actions",
        render: () => {
          if (type === "shared") {
            return (
              <Tooltip title="Clone">
                <CopyOutlined
                  style={{
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            );
          }
          return (
            <div className=" w-100 d-flex justify-content-around gap-2">
              <Tooltip title="Share">
                <ShareAltOutlined
                  style={{
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Tooltip title="Clone">
                <CopyOutlined
                  style={{
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteFilled
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ];
  };

  return (
    <>
      <ProjectReports pageType="project-custom-reports" />

      <Container className="mt-4">
        {isLoading && (
          <>
            <div className="row d-flex justify-content-center">
              <LoadingComponent />
            </div>
          </>
        )}

        {!isLoading && accessLevel === "0" && (
          <div className="row">
            <div className="text-center">
              <Text>
                You do not have the necessary permissions to access this page.
              </Text>
            </div>
          </div>
        )}
        {!isLoading && accessLevel > 0 && (
          <div className=" bg-white p-3 rounded-2">
            <div className=" d-flex justify-content-between my-2 mb-4">
              <div className=" d-flex flex-column">
                {/* <Text className={`${styles.header}`}>Custom Reports</Text> */}
                <Input
                  placeholder="Search"
                  // onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ width: "200px" }}
                  suffix={<SearchOutlined style={{ opacity: "50%" }} />}
                />
              </div>
              <Button
                type="primary"
                onClick={() => {
                  // Add the searchParams to the navigation route
                  navigate({
                    pathname: "create-new-report",
                  });
                }}
              >
                Create Report
              </Button>
            </div>
            {/* My Reports Collapse */}
            <Collapse
              defaultActiveKey={["1"]}
              className="mb-4"
              ghost
              expandIconPosition={"start"}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{ background: "#F8F8F8" }}
            >
              <Panel header={`My Reports (${dataSource.length})`} key="1">
                <Table
                  dataSource={dataSource}
                  columns={getColumns("default")}
                  pagination={false}
                  bordered
                  loading={
                    isLoading ? { indicator: <LoadingComponent /> } : false
                  }
                />
              </Panel>
            </Collapse>
            {/* Shared Reports Collapse */}
            <Collapse
              defaultActiveKey={[""]}
              className="mb-4"
              ghost
              expandIconPosition={"start"}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{ background: "#F8F8F8" }}
            >
              <Panel
                header={`Shared Reports (${sharedDataSource.length})`}
                key="1"
              >
                <Table
                  dataSource={sharedDataSource}
                  columns={getColumns("shared")}
                  pagination={false}
                  bordered
                  loading={
                    isSharedLoading
                      ? { indicator: <LoadingComponent /> }
                      : false
                  }
                />
              </Panel>
            </Collapse>
          </div>
        )}
      </Container>
    </>
  );
}
