import React, { useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ProjectHeader from "components/project-header";
import styles from "./create-update-custom-reports.module.css";
import Container from "components/container";
import ButtonComponent from "components/button-component";
import {
  Table,
  Tooltip,
  DatePicker,
  Button,
  Tabs,
  Input,
  Modal,
  ConfigProvider,
  Switch,
} from "antd";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import { ReportServices } from "apis/Services/ReportService";
import ExportReports from "components/export-reports";
import dayjs from "dayjs";

import {
  CopyOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingFilled,
  ShareAltOutlined,
} from "@ant-design/icons";
import moment from "moment";

export default function CreateUpdateCustomReports() {
  const { company_id, id, reportName } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [editedData, setEditedData] = useState([]);
  const [unusedColumnsInViews, setUnusedColumnsInViews] = useState([]);

  const [searchParams] = useSearchParams();

  const newTabIndex = useRef(0);
  const navigate = useNavigate();

  let reportPageTitle = reportName;
  const reportOwner = searchParams.get("owner");
  let reportTabName = searchParams.get("tab");

  const [data, setData] = useState([]);

  const [reportDescription, setReportDescription] = useState("");
  const [alignedData, setAlignedData] = useState({});
  const [fullReportsAlignedData, setFullReportsAlignedData] = useState({});
  const [reportTabs, setReportTabs] = useState([]);
  const [newReportTabs, setNewReportTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const [filteredInfo, setFilteredInfo] = useState({});

  const isEditMode = Boolean(reportName);
  const [newReportName, setNewReportName] = useState("");
  const [newReportDescription, setNewReportDescription] = useState("");

  const [tabsData, setTabsData] = useState({});
  const [unsavedTabs, setUnsavedTabs] = useState({});

  const presetAlignedData = {
    specTitle: {
      label: "Submittal Title",
      type: "string",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: 0,
    },
    specNumber: {
      label: "Submittal Number",
      type: "string",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: 1,
    },
    specRevision: {
      label: "Submittal Revision",
      type: "int",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: 2,
    },
  };

  function getData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName: reportName ? reportName : "",
      reportTabName: reportTabName ? reportTabName : "",
    };
    ReportServices.getDataToCreateUpdateCustomReport(ReqObj)
      .then((res) => {
        if (Object.keys(res?.reportsAlignedData).length < 1) {
          setTabsData((prev) => ({
            ...prev,
            [reportTabName]: {
              reportInfo: {
                reportDescription: res?.reportInfo?.reportDescription,
                reportTabs: res?.reportInfo?.reportTabs,
              },
              procurementData: res?.procurementLogEntriesCleaned,
              alignedData: presetAlignedData,
              fullReportsAlignedData: res?.fullReportsAlignedData,
              accessLevel: parseInt(res?.userData?.accessLevel),
            },
          }));
          setAlignedData(presetAlignedData);
        } else {
          setTabsData((prev) => ({
            ...prev,
            [reportTabName]: {
              reportInfo: {
                reportDescription: res?.reportInfo?.reportDescription,
                reportTabs: res?.reportInfo?.reportTabs,
              },
              procurementData: res?.procurementLogEntriesCleaned,
              alignedData: res?.reportsAlignedData,
              fullReportsAlignedData: res?.fullReportsAlignedData,
              accessLevel: parseInt(res?.userData?.accessLevel),
            },
          }));
          setAlignedData(res?.reportsAlignedData);
        }

        setFullReportsAlignedData(res?.fullReportsAlignedData);
        setReportDescription(res?.reportInfo?.reportDescription);
        setReportTabs(res?.reportInfo?.reportTabs);
        setData(res?.procurementLogEntriesCleaned);
        setAccessLevel(parseInt(res?.userData?.accessLevel));
        setUnusedColumnsInViews(res?.unusedColumnsInViews);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setisLoading(false);
        setEditedData([]);
      });
  }
  useEffect(() => {
    // map alignedData and set filteredInfo
    const updatedFilteredInfo = Object.entries(alignedData).reduce(
      (acc, [key, value]) => {
        if (value.filtering) {
          acc[key] = value.filtering.split(",").map((item) => item.trim());
        }
        return acc;
      },
      {}
    );

    setFilteredInfo(updatedFilteredInfo);
  }, [alignedData]);

  useEffect(() => {
    getData();
  }, []);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let columns = [];

  if (alignedData?.group) {
    columns.push({
      title: "Group",
      dataIndex: "group",
      key: "group",
      align: "left",
      width: 200,
      fixed: "left",
    });
  } else {
    columns.push(
      {
        title: "Submittal Title",
        dataIndex: "specTitle",
        key: "specTitle",
        align: "left",
        width: 400,
        // fixed: "left",
        defaultSortOrder:
          alignedData?.specTitle?.sorting === "Descending"
            ? "descend"
            : alignedData?.specTitle?.sorting === "Ascending"
            ? "ascend"
            : "",
        sorter: (a, b) => a.specTitle?.localeCompare(b.specTitle),
      },
      {
        title: "Submittal Number",
        dataIndex: "specNumber",
        key: "specNumber",
        align: "center",
        width: 150,
        // fixed: "left",
        defaultSortOrder:
          alignedData?.specNumber?.sorting === "Descending"
            ? "descend"
            : alignedData?.specNumber?.sorting === "Ascending"
            ? "ascend"
            : "",
        sorter: (a, b) => a.specNumber?.localeCompare(b.specNumber),
      },
      {
        title: "Submittal Revision",
        dataIndex: "specRevision",
        key: "specRevision",
        align: "center",
        width: 150,
        // fixed: "left",
        defaultSortOrder:
          alignedData?.specRevision?.sorting === "Descending"
            ? "descend"
            : alignedData?.specRevision?.sorting === "Ascending"
            ? "ascend"
            : "",
        sorter: (a, b) => Number(a.specRevision) - Number(b.specRevision),
      }
    );
  }

  columns = [
    ...columns,
    ...Object.entries(alignedData)
      .filter(
        ([key]) =>
          ![
            "specTitle",
            "specNumber",
            "specRevision",
            "specSection",
            "group",
          ].includes(key)
      )
      .sort((a, b) => a[1].index - b[1].index)
      .map(([key, value]) => {
        const uniqueValues = [...new Set(dataSource.map((item) => item[key]))];
        const columnConfig = {
          title: value.label || key,
          dataIndex: key,
          key: key,
          align: "center",
          filterMode: "tree",
          filterSearch: true,
          filteredValue: filteredInfo[key] || null,
          width: 200,
        };

        if (value.type === "int") {
          columnConfig.filters = uniqueValues
            .filter((v) => v !== undefined && v !== null)
            .map((uniqueValue) => ({
              text: uniqueValue !== "" ? uniqueValue : "[Blank]",
              value: uniqueValue !== "" ? uniqueValue : "",
            }));

          columnConfig.onFilter = (filterValue, record) => {
            const cellValue = record[key];
            if (filterValue === "") {
              return cellValue === "";
            }
            return String(cellValue).startsWith(filterValue);
          };

          columnConfig.sorter = (a, b) => {
            const numA = Number(a[key]);
            const numB = Number(b[key]);
            return numA - numB;
          };
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
        } else if (value.type === "string") {
          columnConfig.filters = uniqueValues
            .filter((v) => v !== undefined && v !== null)
            .map((uniqueValue) => ({
              text: uniqueValue !== "" ? uniqueValue : "[Blank]",
              value: uniqueValue !== "" ? uniqueValue : "",
            }));

          columnConfig.onFilter = (filterValue, record) => {
            const cellValue = record[key];
            if (filterValue === "") {
              return cellValue === "";
            }
            return String(cellValue).startsWith(filterValue);
          };

          columnConfig.sorter = (a, b) => {
            const strA = String(a[key]);
            const strB = String(b[key]);
            return strA.localeCompare(strB);
          };
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
        } else if (value.type === "date") {
          columnConfig.filterDropdown = ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            const handleConfirm = () => {
              if (startDate && endDate) {
                // Format dates as MM-DD-YYYY
                const formattedStartDate = startDate.format("MM-DD-YYYY");
                const formattedEndDate = endDate.format("MM-DD-YYYY");

                const filteringValue = `${formattedStartDate},${formattedEndDate}`;
                const currentTabKey = selectedTab || reportTabName;

                setTabsData((prev) => {
                  const updatedTabsData = { ...prev };

                  updatedTabsData[currentTabKey] = {
                    ...updatedTabsData[currentTabKey],
                    alignedData: {
                      ...updatedTabsData[currentTabKey]?.alignedData,
                      [key]: {
                        ...updatedTabsData[currentTabKey]?.alignedData[key],
                        filtering: filteringValue,
                      },
                    },
                  };

                  return updatedTabsData;
                });

                // Set the selected keys with the formatted dates
                setSelectedKeys([formattedStartDate, formattedEndDate]);

                setFilteredInfo((prev) => ({
                  ...prev,
                  [key]: {
                    startDate: startDate,
                    endDate: endDate,
                  },
                }));

                setUnsavedTabs((prev) => ({
                  ...prev,
                  [selectedTab]: true,
                }));

                confirm();
              }
            };

            const handleReset = () => {
              const currentTabKey = selectedTab || reportTabName;

              setTabsData((prev) => {
                const updatedTabsData = { ...prev };

                updatedTabsData[currentTabKey] = {
                  ...updatedTabsData[currentTabKey],
                  alignedData: {
                    ...updatedTabsData[currentTabKey]?.alignedData,
                    [key]: {
                      ...updatedTabsData[currentTabKey]?.alignedData[key],
                      filtering: "",
                    },
                  },
                };

                return updatedTabsData;
              });
              setStartDate(null);
              setEndDate(null);
              setSelectedKeys([]);
              clearFilters();
              confirm();
            };

            return (
              <div style={{ padding: 8 }}>
                <div>
                  <strong style={{ width: "50%", marginBottom: 8 }}>
                    Pick a range
                  </strong>
                </div>
                <div className="d-flex gap-2 justify-content-between">
                  <DatePicker
                    // value={startDate}
                    onChange={(date) => setStartDate(date)}
                    format="MM-DD-YYYY"
                    style={{ width: "50%", marginBottom: 8 }}
                    placeholder="Start Date"
                  />
                  <DatePicker
                    // value={endDate}
                    onChange={(date) => setEndDate(date)}
                    format="MM-DD-YYYY"
                    style={{ width: "50%", marginBottom: 8 }}
                    placeholder="End Date"
                  />
                </div>
                <div className="d-flex justify-content-between flex-row-reverse">
                  <Button
                    type="primary"
                    onClick={handleConfirm}
                    size="small"
                    disabled={!startDate || !endDate}
                  >
                    OK
                  </Button>
                  <Button
                    onClick={handleReset}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            );
          };
          columnConfig.onFilter = (value, record) => {
            const recordDate = moment(record[key], "MM-DD-YYYY");

            if (!recordDate.isValid()) {
              // console.error("Invalid record date:", record[key]);
              return false;
            }

            let startDate = filteredInfo[key] ? filteredInfo[key][0] : null;
            let endDate = filteredInfo[key] ? filteredInfo[key][1] : null;

            // Convert startDate and endDate to moment objects
            const formattedStartDate = startDate
              ? moment(startDate, "MM-DD-YYYY")
              : null;
            const formattedEndDate = endDate
              ? moment(endDate, "MM-DD-YYYY")
              : null;

            // Check if the formattedStartDate and formattedEndDate are valid
            if (formattedStartDate && !formattedStartDate.isValid()) {
              console.error("Invalid start date:", startDate);
              return false;
            }

            if (formattedEndDate && !formattedEndDate.isValid()) {
              console.error("Invalid end date:", endDate);
              return false;
            }

            const isDateInRange = recordDate.isBetween(
              formattedStartDate,
              formattedEndDate,
              null,
              "[]"
            );

            // Return true if the date is within the range, false otherwise
            return isDateInRange;
          };

          columnConfig.sorter = (a, b) => {
            // Handle cases where the date might be null or undefined
            const dateA = a[key] ? dayjs(a[key]) : null;
            const dateB = b[key] ? dayjs(b[key]) : null;

            if (dateA && dateB) {
              return dateA.diff(dateB);
            }

            if (!dateA) return 1;
            if (!dateB) return -1;

            return 0;
          };
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
          columnConfig.render = (text) =>
            text ? dayjs(text).format("MM-DD-YYYY") : "";
        }

        return columnConfig;
      }),
  ];

  useEffect(() => {
    if (!isLoading) {
      const updatedDataSource = data.map((item, index) => ({
        key: item?.entryID || `entryID-${index}`,
        specTitle: `${item?.specTitle}`,
        specNumber: `${item?.specNumber}`,
        specRevision: `${item?.specRevision}`,
        ...Object.keys(alignedData).reduce((acc, column) => {
          acc[column] = item[column];
          return acc;
        }, {}),
      }));
      setDataSource(updatedDataSource);
    }
  }, [data, isLoading, alignedData]);

  const onTabChange = (key) => {
    // Update search params and selected tab
    setSearchParam((prevParams) => {
      prevParams.set("tab", key);
      return prevParams;
    });
    setSelectedTab(key);
    reportTabName = key;

    // Check if tab data is already loaded
    if (!tabsData[key]) {
      getData(key);
    } else {
      // Use locally stored data
      const tabData = tabsData[key];
      setReportDescription(tabData.reportInfo.reportDescription);
      setReportTabs(tabData.reportInfo.reportTabs);
      setData(tabData.procurementData);
      setAlignedData(tabData.alignedData);
      setFullReportsAlignedData(tabData.fullReportsAlignedData);
    }
  };

  const saveReport = () => {
    const tabsToSave = Object.keys(unsavedTabs);

    tabsToSave.forEach((tabName) => {
      const tabData = tabsData[tabName];

      // Prepare request object for each tab
      const saveRequest = {
        company: company_id,
        project: id,
        reportName: newReportName || reportName,
        reportTabName: tabName,
        reportDescription: newReportDescription || reportDescription,
        procurementData: tabData.procurementData,
        alignedData: tabData.alignedData,
      };

      // Call backend service to save tab
      ReportServices.saveCustomReportTab(saveRequest)
        .then(() => {
          // Remove tab from unsaved tabs after successful save
          setUnsavedTabs((prev) => {
            const updated = { ...prev };
            delete updated[tabName];
            return updated;
          });
        })
        .catch((error) => {
          console.error(`Failed to save tab ${tabName}:`, error);
          // Optionally show error to user
        });
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    const currentTabKey = selectedTab || reportTabName;

    // Update tabsData with the new filters
    setTabsData((prev) => {
      const updatedTabsData = { ...prev };

      updatedTabsData[currentTabKey] = {
        ...updatedTabsData[currentTabKey],
        alignedData: {
          ...updatedTabsData[currentTabKey].alignedData,
          ...Object.fromEntries(
            Object.entries(filters).map(([key, value]) => [
              key,
              {
                ...updatedTabsData[currentTabKey].alignedData[key],
                filtering: value ? value.join(",") : "",
              },
            ])
          ),
          ...(sorter && sorter.column
            ? {
                [sorter.column.dataIndex]: {
                  ...updatedTabsData[currentTabKey].alignedData[
                    sorter.column.dataIndex
                  ],
                  sorting:
                    sorter.order === "ascend"
                      ? "Ascending"
                      : sorter.order === "descend"
                      ? "Descending"
                      : "",
                },
              }
            : {}),
        },
      };

      return updatedTabsData;
    });

    // Update filteredInfo state
    setFilteredInfo(filters);

    // Mark the current tab as having unsaved changes
    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));
  };

  // Search item names or submittal number
  const [searchQuery, setSearchQuery] = useState("");

  const isFilteredInfoEmpty = (filteredInfo) => {
    if (!filteredInfo || typeof filteredInfo !== "object") return true;
    const values = Object.values(filteredInfo);
    return values.length === 0 || values.every((value) => value == null);
  };

  const [isNewTabModalVisible, setIsNewTabModalVisible] = useState(false);
  const [newTabName, setNewTabName] = useState("");

  const add = () => {
    setIsNewTabModalVisible(true);
  };

  const handleNewTabConfirm = () => {
    if (newTabName.trim()) {
      // const currentTabs = Array.isArray(reportTabs) ? reportTabs : [];

      // setSearchParam((prevParams) => {
      //   prevParams.set("tab", newTabName.trim());
      //   return prevParams;
      // });

      // setSelectedTab(newTabName.trim());
      setNewReportTabs([...newReportTabs, newTabName.trim()]);
      setIsNewTabModalVisible(false);
      setNewTabName("");
    }
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      // remove(targetKey);
    }
  };

  const handleEditTab = (tabName, index) => {
    console.log(`Editing tab: ${tabName} at index ${index}`);
    // Implement your editing logic here
  };

  useEffect(() => {
    console.log(tabsData, "?!?@?!?");
  }, [tabsData]);

  // fullReportsAlignedData
  const columnInfoData = Object.entries(fullReportsAlignedData || {})
    .filter(([key, value]) => value.label)
    .sort(([, a], [, b]) => (a.label || "").localeCompare(b.label || ""))
    .filter(
      ([key]) => !unusedColumnsInViews.includes(key) && key !== "entryID"
    );

  const filteredcolumnsInfo = columnInfoData.filter(([, value]) =>
    value.label?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Container>
        <div className="row mt-4">
          {isLoading && !accessLevel ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              {/* When Request Fails  */}
              {!accessLevel ? (
                <div className="row">
                  <div className="text-center">
                    <Text>Something went wrong, please try again later.</Text>
                  </div>
                </div>
              ) : accessLevel < 2 ? (
                // View for insufficient permissions
                <div className="row">
                  <div className="text-center">
                    <Text>
                      You do not have the necessary permissions to access this
                      page.
                    </Text>
                  </div>
                </div>
              ) : (
                // Main view
                <div className="d-flex" style={{ overflow: "hidden" }}>
                  <div
                    style={{ flex: 1, overflowY: "auto", paddingRight: "10px" }}
                  >
                    <>
                      <div className=" bg-white p-3 rounded-3 mb-4">
                        <div className=" d-flex justify-content-between ">
                          <div className=" d-flex flex-column gap-2">
                            <div>
                              <small>
                                Report Name{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </small>
                              <Input
                                maxLength={30}
                                showCount
                                defaultValue={
                                  reportPageTitle ? reportPageTitle : ""
                                }
                                onChange={(e) => {
                                  setNewReportName(e.target.value);
                                }}
                                placeholder="Report Name"
                              />
                            </div>
                            <div>
                              <small>Report Description</small>
                              <Input
                                maxLength={100}
                                showCount
                                defaultValue={
                                  reportDescription ? reportDescription : ""
                                }
                                onChange={(e) => {
                                  setNewReportDescription(e.target.value);
                                }}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                          <div className="d-flex gap-2">
                            <Tooltip title="Share Report">
                              <Button
                                shape="circle"
                                icon={
                                  <ShareAltOutlined
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  />
                                }
                              />
                            </Tooltip>
                            <Tooltip title="Clone Report">
                              <Button
                                shape="circle"
                                icon={
                                  <CopyOutlined
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  />
                                }
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <hr />
                        <div className=" d-flex justify-content-end">
                          {/* GROUPING HERE */}

                          <Button
                            onClick={saveReport}
                            type="primary"
                            disabled={!newReportName && !reportName}
                          >
                            Save Report
                          </Button>
                        </div>
                      </div>

                      <Tabs
                        type="editable-card"
                        onChange={onTabChange}
                        onEdit={onEdit}
                        tabBarExtraContent={
                          <Button
                            onClick={() => {
                              setFilteredInfo({});

                              // update tab data
                              setTabsData((prev) => {
                                const currentTabKey =
                                  selectedTab || reportTabName;
                                const updatedTabsData = { ...prev };

                                updatedTabsData[currentTabKey] = {
                                  ...updatedTabsData[currentTabKey],
                                  alignedData: Object.fromEntries(
                                    Object.entries(
                                      updatedTabsData[currentTabKey]
                                        ?.alignedData || {}
                                    ).map(([key, value]) => [
                                      key,
                                      {
                                        ...value,
                                        filtering: "",
                                      },
                                    ])
                                  ),
                                };

                                return updatedTabsData;
                              });

                              setUnsavedTabs((prev) => ({
                                ...prev,
                                [selectedTab]: true,
                              }));
                            }}
                            style={{
                              marginBottom: "20px",
                            }}
                            disabled={isFilteredInfoEmpty(filteredInfo)}
                          >
                            Clear Filters
                          </Button>
                        }
                        activeKey={selectedTab ? selectedTab : reportTabName}
                        className="rounded-3"
                        addIcon={
                          <div
                            style={{
                              width: "30px",
                              height: "42px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PlusOutlined />
                          </div>
                        }
                        tabBarStyle={{
                          marginBottom: "-1px",
                        }}
                        style={{
                          background: "white",
                          padding: "20px 10px 10px 10px ",
                        }}
                        items={[
                          ...(reportTabs || []),
                          ...(newReportTabs || []),
                        ]?.map((item, index) => ({
                          // label: `${item}`,
                          label: (
                            <div className="d-flex align-items-center gap-2">
                              {selectedTab === item && (
                                <SettingFilled
                                  onClick={() => handleEditTab(item, index)}
                                  style={{
                                    padding: "0px",
                                    marginRight: "0px",
                                    cursor: "pointer",
                                    color: "black",
                                  }}
                                />
                              )}
                              <span>{item}</span>
                            </div>
                          ),
                          key: item,
                          icon: <LoadingComponent />,
                          children: (
                            <div>
                              <Table
                                columns={columns}
                                dataSource={dataSource}
                                scroll={{ x: 500, y: "70vh" }}
                                bordered
                                loading={
                                  isLoading
                                    ? { indicator: <LoadingComponent /> }
                                    : false
                                }
                                pagination={false}
                                onChange={handleChange}
                                size="small"
                              />
                              <div className=" p-2 mt-1">
                                <i>Preview: Only displaying first 10 rows</i>
                              </div>
                            </div>
                          ),
                        }))}
                      />

                      <Modal
                        title="Add New Tab"
                        open={isNewTabModalVisible}
                        onOk={handleNewTabConfirm}
                        onCancel={() => {
                          setIsNewTabModalVisible(false);
                          setNewTabName("");
                        }}
                        okButtonProps={{
                          disabled: !newTabName.trim(),
                        }}
                      >
                        <Input
                          placeholder="Enter tab name"
                          value={newTabName}
                          onChange={(e) => setNewTabName(e.target.value)}
                          maxLength={30}
                          showCount
                        />
                      </Modal>
                    </>
                  </div>
                  {/* Right-side scrollable div */}
                  <div
                    className="rounded-3"
                    style={{
                      width: "300px",
                      overflowY: "auto",
                      background: "#FAFAFA",
                      height: "90vh",
                      position: "relative",
                    }}
                  >
                    <div
                      className=" d-flex flex-column gap-3 w-100 pt-3 px-3 pb-3 border-bottom mb-2 bg-white "
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        background: "#FFFFFF",
                      }}
                    >
                      <div className=" w-100">
                        <Button type="primary" className="w-100">
                          Create Custom Column
                        </Button>
                      </div>
                      <Input
                        placeholder="Search Columns"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        disabled={isLoading}
                        suffix={<SearchOutlined style={{ opacity: "50%" }} />}
                      />
                      <div className=" d-flex justify-content-between align-items-center">
                        <small className="fw-semibold">Toggle All</small>
                        <Switch size="small" />
                      </div>
                    </div>
                    <div className=" px-3">
                      {filteredcolumnsInfo ? (
                        filteredcolumnsInfo.map(([key, value], index) => (
                          <div
                            key={key}
                            className="d-flex justify-content-between align-items-center"
                            style={{
                              borderBottom: "1px solid #e8e8e8",
                              paddingBottom: "8px",
                              paddingTop: index !== 0 ? "8px" : "8px",
                            }}
                          >
                            <small>{value.label}</small>
                            <Switch
                              checked={value.hidden || false}
                              disabled={
                                key === "specTitle" ||
                                key === "specRevision" ||
                                key === "specNumber"
                              }
                              size="small"
                              onChange={(checked) => {
                                // Handle state updates or callback here
                                // console.log(`${key} hidden:`, checked);
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <p>No data available.</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
}
