import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ProjectHeader from "components/project-header";
import styles from "./custom-reports-details.module.css";
import Container from "components/container";
import ButtonComponent from "components/button-component";
import { Table, Tooltip, DatePicker, Button, Tabs, Input } from "antd";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import { ReportServices } from "apis/Services/ReportService";
import ExportReports from "components/export-reports";
import dayjs from "dayjs";

import {
  CopyOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import moment from "moment";

export default function CustomReportsDetails() {
  const { company_id, id, reportName } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [editedData, setEditedData] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let reportPageTitle = reportName;
  const reportOwner = searchParams.get("owner");
  let reportTabName = searchParams.get("tab");

  const [data, setData] = useState([]);

  const [reportDescription, setReportDescription] = useState("");
  const [alignedData, setAlignedData] = useState({});
  const [reportTabs, setReportTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const [filteredInfo, setFilteredInfo] = useState({});

  function getData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName,
      reportTabName,
      reportOwner,
    };
    ReportServices.readCustomReport(ReqObj)
      .then((res) => {
        setReportDescription(res?.reportInfo?.reportDescription);
        setReportTabs(res?.reportInfo?.reportTabs);
        setData(res?.procurementLogEntries);
        setAccessLevel(parseInt(res?.userData?.accessLevel));
        setProjectName(res?.projectData?.projectName);
        setProjectAddress(res?.projectData?.projectAddress);
        setAlignedData(res?.reportsAlignedData);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setisLoading(false);
        setEditedData([]);
      });
  }

  useEffect(() => {
    // map alignedData and set filteredInfo
    const updatedFilteredInfo = Object.entries(alignedData).reduce(
      (acc, [key, value]) => {
        if (value.filtering) {
          acc[key] = value.filtering.split(",").map((item) => item.trim());
        }
        return acc;
      },
      {}
    );

    setFilteredInfo(updatedFilteredInfo);
  }, [alignedData]);

  useEffect(() => {
    getData();
  }, []);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let columns = [];

  if (alignedData?.group) {
    columns.push({
      title: "Group",
      dataIndex: "group",
      key: "group",
      align: "left",
      width: 200,
      fixed: "left",
    });
  }

  columns = [
    ...columns,
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      align: "left",
      width: 400,
      fixed: "left",
      defaultSortOrder:
        alignedData?.itemName?.sorting === "Descending"
          ? "descend"
          : alignedData?.itemName?.sorting === "Ascending"
          ? "ascend"
          : "",
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
    },
    {
      title: "Submittal Number",
      dataIndex: "submittalNumber",
      key: "submittalNumber",
      align: "center",
      width: 150,
      fixed: "left",
      defaultSortOrder:
        alignedData?.specNumber?.sorting === "Descending"
          ? "descend"
          : alignedData?.specNumber?.sorting === "Ascending"
          ? "ascend"
          : "",
      sorter: (a, b) => a.submittalNumber.localeCompare(b.submittalNumber),
    },
    {
      title: "Submittal Revison",
      dataIndex: "submittalRevison",
      key: "submittalRevison",
      align: "center",
      width: 150,
      fixed: "left",
      defaultSortOrder:
        alignedData?.submittalRevison?.sorting === "Descending"
          ? "descend"
          : alignedData?.submittalRevison?.sorting === "Ascending"
          ? "ascend"
          : "",
      sorter: (a, b) => Number(a.submittalRevison) - Number(b.submittalRevison),
    },
    ...Object.entries(alignedData)
      .filter(
        ([key]) =>
          ![
            "specTitle",
            "specNumber",
            "specRevision",
            "specSection",
            "group",
          ].includes(key)
      )
      .sort((a, b) => a[1].index - b[1].index)
      .map(([key, value]) => {
        const uniqueValues = [...new Set(dataSource.map((item) => item[key]))];
        const columnConfig = {
          title: value.label || key,
          dataIndex: key,
          key: key,
          align: "center",
          filterMode: "tree",
          filterSearch: true,
          filteredValue: filteredInfo[key] || null,
          width: 200,
        };

        if (value.type === "int") {
          columnConfig.filters = uniqueValues
            .filter((v) => v !== undefined && v !== null)
            .map((uniqueValue) => ({
              text: uniqueValue !== "" ? uniqueValue : "[Blank]",
              value: uniqueValue !== "" ? uniqueValue : "",
            }));

          columnConfig.onFilter = (filterValue, record) => {
            const cellValue = record[key];
            if (filterValue === "") {
              return cellValue === "";
            }
            return String(cellValue).startsWith(filterValue);
          };

          columnConfig.sorter = (a, b) => {
            const numA = Number(a[key]);
            const numB = Number(b[key]);
            return numA - numB;
          };
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
        } else if (value.type === "string") {
          columnConfig.filters = uniqueValues
            .filter((v) => v !== undefined && v !== null)
            .map((uniqueValue) => ({
              text: uniqueValue !== "" ? uniqueValue : "[Blank]",
              value: uniqueValue !== "" ? uniqueValue : "",
            }));

          columnConfig.onFilter = (filterValue, record) => {
            const cellValue = record[key];
            if (filterValue === "") {
              return cellValue === "";
            }
            return String(cellValue).startsWith(filterValue);
          };

          columnConfig.sorter = (a, b) => {
            const strA = String(a[key]);
            const strB = String(b[key]);
            return strA.localeCompare(strB);
          };
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
        } else if (value.type === "date") {
          columnConfig.filterDropdown = ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            const handleConfirm = () => {
              if (startDate && endDate) {
                // Format dates as MM-DD-YYYY
                const formattedStartDate = startDate.format("MM-DD-YYYY");
                const formattedEndDate = endDate.format("MM-DD-YYYY");

                setFilteredInfo((prev) => ({
                  ...prev,
                  [key]: {
                    startDate: startDate,
                    endDate: endDate,
                  },
                }));

                // Set the selected keys with the formatted dates
                setSelectedKeys([formattedStartDate, formattedEndDate]);

                confirm();
              }
            };

            const handleReset = () => {
              setStartDate(null);
              setEndDate(null);
              setSelectedKeys([]);
              clearFilters();
              confirm();
            };

            return (
              <div style={{ padding: 8 }}>
                <div>
                  <strong style={{ width: "50%", marginBottom: 8 }}>
                    Pick a range
                  </strong>
                </div>
                <div className="d-flex gap-2 justify-content-between">
                  <DatePicker
                    // value={startDate}
                    onChange={(date) => setStartDate(date)}
                    format="MM-DD-YYYY"
                    style={{ width: "50%", marginBottom: 8 }}
                    placeholder="Start Date"
                  />
                  <DatePicker
                    // value={endDate}
                    onChange={(date) => setEndDate(date)}
                    format="MM-DD-YYYY"
                    style={{ width: "50%", marginBottom: 8 }}
                    placeholder="End Date"
                  />
                </div>
                <div className="d-flex justify-content-between flex-row-reverse">
                  <Button
                    type="primary"
                    onClick={handleConfirm}
                    size="small"
                    disabled={!startDate || !endDate}
                  >
                    OK
                  </Button>
                  <Button
                    onClick={handleReset}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            );
          };
          columnConfig.onFilter = (value, record) => {
            const recordDate = moment(record[key], "MM-DD-YYYY");

            if (!recordDate.isValid()) {
              // console.error("Invalid record date:", record[key]);
              return false;
            }

            let startDate = filteredInfo[key] ? filteredInfo[key][0] : null;
            let endDate = filteredInfo[key] ? filteredInfo[key][1] : null;

            // Convert startDate and endDate to moment objects
            const formattedStartDate = startDate
              ? moment(startDate, "MM-DD-YYYY")
              : null;
            const formattedEndDate = endDate
              ? moment(endDate, "MM-DD-YYYY")
              : null;

            // Check if the formattedStartDate and formattedEndDate are valid
            if (formattedStartDate && !formattedStartDate.isValid()) {
              console.error("Invalid start date:", startDate);
              return false;
            }

            if (formattedEndDate && !formattedEndDate.isValid()) {
              console.error("Invalid end date:", endDate);
              return false;
            }

            const isDateInRange = recordDate.isBetween(
              formattedStartDate,
              formattedEndDate,
              null,
              "[]"
            );

            // Return true if the date is within the range, false otherwise
            return isDateInRange;
          };

          columnConfig.sorter = (a, b) => {
            // Handle cases where the date might be null or undefined
            const dateA = a[key] ? dayjs(a[key]) : null;
            const dateB = b[key] ? dayjs(b[key]) : null;

            if (dateA && dateB) {
              return dateA.diff(dateB);
            }

            if (!dateA) return 1;
            if (!dateB) return -1;

            return 0;
          };
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
          columnConfig.render = (text) =>
            text ? dayjs(text).format("MM-DD-YYYY") : "";
        }

        return columnConfig;
      }),
  ];

  useEffect(() => {
    if (!isLoading) {
      const updatedDataSource = data.map((item, index) => ({
        key: item?.entryID || `entryID-${index}`,
        itemName: `${item?.specTitle}`,
        submittalNumber: `${item?.specNumber}`,
        submittalRevison: `${item?.specRevision}`,
        ...Object.keys(alignedData).reduce((acc, column) => {
          acc[column] = item[column];
          return acc;
        }, {}),
      }));
      setDataSource(updatedDataSource);
    }
  }, [data, isLoading, alignedData]);

  const onTabChange = (key) => {
    // Update the search params
    setSearchParam((prevParams) => {
      prevParams.set("tab", key);
      return prevParams;
    });

    // Update local state
    reportTabName = key;
    setSelectedTab(key);

    // Fetch data for the new tab
    getData();
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", filters);
    setFilteredInfo(filters);
  };

  // Search item names or submittal number
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = dataSource.filter(
    (item) =>
      item.specTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.submittalNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const isFilteredInfoEmpty = (filteredInfo) => {
    if (!filteredInfo || typeof filteredInfo !== "object") return true;
    const values = Object.values(filteredInfo);
    return values.length === 0 || values.every((value) => value == null);
  };

  return (
    <>
      <Container>
        {accessLevel > 1 && (
          <>
            <div className=" bg-white p-3 rounded-3 mt-4">
              <div className=" d-flex justify-content-between ">
                <div className=" d-flex flex-column">
                  <Text className={`${styles.header}`}>{reportPageTitle}</Text>
                  <p>{reportDescription}</p>
                </div>
                <div className="d-flex gap-2">
                  <Tooltip title="Share Report">
                    <Button
                      shape="circle"
                      icon={
                        <ShareAltOutlined
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Clone Report">
                    <Button
                      shape="circle"
                      icon={
                        <CopyOutlined
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      }
                    />
                  </Tooltip>

                  <ExportReports
                    data={filteredData}
                    columns={columns}
                    accessLevel={accessLevel}
                    editedData={editedData}
                    isLoading={isLoading}
                    reportTitle={reportPageTitle}
                    projectName={projectName}
                    projAddress={projectAddress}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <Input
                    placeholder="Search Item Name or Submittal Number"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    disabled={isLoading}
                    style={{ width: "300px" }}
                    suffix={<SearchOutlined style={{ opacity: "50%" }} />}
                  />
                  {/* {filteredInfo && (
                    <Button
                      onClick={() => {
                        setFilteredInfo({});
                      }}
                      disabled={isFilteredInfoEmpty(filteredInfo)}
                    >
                      Clear Filters
                    </Button>
                  )} */}
                </div>
                <div className="d-flex gap-2">
                  <Button
                    type="primary"
                    onClick={() => {
                      // Extract current searchParams and convert them to an object
                      const paramsObject = Object.fromEntries(
                        searchParams.entries()
                      );

                      // Add the searchParams to the navigation route
                      navigate({
                        pathname: "edit",
                        search: new URLSearchParams(paramsObject).toString(),
                      });
                    }}
                  >
                    Edit Report
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row mt-4">
          {isLoading && !accessLevel ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              {/* View for no access level */}
              {!accessLevel ? (
                <div className="row">
                  <div className="text-center">
                    <Text>Something went wrong, please try again later.</Text>
                  </div>
                </div>
              ) : accessLevel < 2 ? (
                // View for insufficient permissions
                <div className="row">
                  <div className="text-center">
                    <Text>
                      You do not have the necessary permissions to access this
                      page.
                    </Text>
                  </div>
                </div>
              ) : (
                // Main view
                <div>
                  <Tabs
                    onChange={onTabChange}
                    activeKey={selectedTab ? selectedTab : reportTabName}
                    tabBarExtraContent={
                      <Button
                        onClick={() => {
                          setFilteredInfo({});
                        }}
                        disabled={isFilteredInfoEmpty(filteredInfo)}
                      >
                        Clear Filters
                      </Button>
                    }
                    className="rounded-3"
                    style={{ background: "white", padding: "5px" }}
                    items={reportTabs.map((item, index) => ({
                      label: `${item}`,
                      key: item,
                      children: (
                        <div>
                          <Table
                            columns={columns}
                            dataSource={filteredData}
                            scroll={{ x: 500, y: "70vh" }}
                            bordered
                            loading={
                              isLoading
                                ? { indicator: <LoadingComponent /> }
                                : false
                            }
                            pagination={{
                              position: ["bottomCenter"],
                              defaultPageSize: 20,
                            }}
                            onChange={handleChange}
                            size="small"
                          />
                        </div>
                      ),
                    }))}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
}
